<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑分组' : '创建分组'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="分组名称" prop="name">
          <el-input
            size="mini"
            v-model.trim="form.data.name"
            placeholder="请输入分组名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="折扣率" prop="discount_type">
          <el-radio-group v-model="form.data.discount_type" v-removeAriaHidden>
            <el-radio :label="1">按商品分类</el-radio>
            <el-radio :label="2">按商品名称</el-radio>
          </el-radio-group>
          <div>
            <el-alert
              v-if="form.data.discount_type == 1"
              title="创建后请在操作中设置折扣体系"
              type="warning"
              :closable="false"
              size="mini">
            </el-alert>
            <el-alert
              v-if="form.data.discount_type == 2"
              title="创建后在添加商品报价时需要手动输入参价对象和折扣率，也可以使用导入询价表功能批量设置"
              type="warning"
              :closable="false"
              size="mini">
            </el-alert>
          </div>
        </el-form-item>
        <el-form-item label="选择用户" prop="user_ids">
          <el-select v-model="form.data.user_ids" filterable multiple clearable placeholder="请选择" @focus="getUser">
            <el-option
              v-for="item in userOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from '@/mixins/table.js'
import { number } from 'echarts'
  export default {
    name: 'companyContractEdit',
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            name: '',
            user_ids: [],
            discount_type: '',
          },
          rules: {
            name: [{ required: true, message:'请输入分组名称', trigger: 'change'}],
            // user_ids: [{ required: true, message:'请选择客户', trigger: 'change'}],
            discount_type: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        userOpt:[],
        userArr:[],
      }
    },
    methods: {
      async getDetail(row) {
        this.isChange = true
        this.userArr = [];
        this.form.data.discount_type = row.discount_type;
        await row.user_arr.forEach(v => {
          this.userArr.push(v)
        });
        await this.getUser()
        await this.$http.get('/admin/group/info',{params:{id:row.id}}).then(res => {
          if(res.code == 1) {
            // this.form.data = res.data;
            this.form.data.id = res.data.id;
            this.form.data.name = res.data.name;
            this.form.data.user_ids = res.data.user_ids.split("|").map(Number)
          }
        })
      },
      // 获取客户列表
      getUser() {
        // 类型 1:列表 2:参数
        this.$http.get('/admin/group/merchantList', {params:{type:2,page:1,count:10000}} ).then(res => {
          if(res.code === 1) {
            this.userOpt = res.data.list.concat(this.userArr);  // 编辑返显时把该分组中的客户添加到客户列表中
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.loading = true;
            let _params = {
              id: this.form.data.id,
              name: this.form.data.name,
              user_ids: this.form.data.user_ids.join("|"),
              discount_type: this.form.data.discount_type,
            };
            let apiUrl = this.isChange ? '/admin/group/edit' : '/admin/group/add'
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 选择企业
      selectFn(item) {
        if (item.value != "无数据") {
          this.form.data.mid = item.mid;
        } else {
          this.form.data.name = "";
        }
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      // 图片上传成功
      handleAvatarSuccess(event, file, fileList) {
        console.log(event.data.path[0])
        if (event.code == 200) {
          this.form.data.contract_url = event.data.path[0];
        } else {
          this.$message({
            type: "error",
            message: event.msg,
          });
        }
      },
      // 移除图片
      handleRemove(file, fileList) {
        this.form.data.contract_url = "";
        this.img_value = [];
      },
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .idPhoto .avatar{
    width: 100% !important;
  }
  .idPhoto .avatar-uploader-icon {
    width: 260px !important;
    height: 170px !important;
    line-height: 170px !important;
    border: 1px dashed #d9d9d9;
  }
  .onBtn-large {
    display: flex;
    justify-content: center;
  }
</style>
