<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select
            v-model="table.params.merchant_id"
            filterable
            placeholder="请选择"
            id="group_id"
            clearable
            @change="onSearch"
          >
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建分组</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    <template v-slot:discount_type="{row}">
      <span>{{toStr({1:'按商品分类',2:'按商品名称'},row.discount_type)}}</span>
    </template>
    <template v-slot:action="{row}">
      <el-button type="text" @click="showEdit(row)">编辑</el-button>
      <el-button type="text" @click="showUser(row)">查看用户</el-button>
      <el-button v-if="row.discount_type == 1" type="text" @click="showRebate(row)">折扣体系</el-button>
      <el-button v-if="row.discount_type == 2" type="text" @click="handlePrice(row)" :class="row.price_type == 1 ? 'greenColor' : 'redColor'">{{row.price_type == 1 ? '使用采购价' : '关闭采购价'}}</el-button>
      <el-button type="text" @click="remove(row)">删除</el-button>
    </template>
    </VTable>

    <el-dialog
      title="查看用户"
      :visible.sync="dialogVisible"
      width="30%">
      <div class="user-list">
        <div v-for="item in userArr" :key="item.id" class="user">{{item.name}}</div>
      </div>
    </el-dialog>

    <Edit ref="edit" @refresh="getTable"></Edit>

    <!-- 折扣体系 -->
    <Rebate ref="rebate" @refresh="getTable"></Rebate>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
// import Rebate from './components/Rebate.vue'
import Rebate from '../merchant/components/Rebate.vue'
import { mixinTable } from '@/mixins/table.js'

export default {
  name: 'UserIndex',
  components: {
    VTable,
    Rebate,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "分组名称", hidden: false },
        { name: "num", label: "用户数量", hidden: false},
        { name: "discount_type", label: "折扣率对象", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "320", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      userArr: [],
      dialogVisible: false,
      merchantArr: [],
    }
  },
  created() {
    this.getTable();
    this.getMerchantSel();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/group/list', {params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      }).finally(() => {
        this.table.loading = false;
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 折扣体系
    showRebate(row) {
      let dom = this.$refs.rebate
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看用户
    showUser(row) {
      this.dialogVisible = true;
      this.$http.get('/admin/group/info',{params:{id:row.id}}).then(res => {
        if(res.code == 1) {
          this.userArr = res.data.user_arr;
        }
      })
    },
    // 删除
    remove(row) {
      if(row.num > 0) {
        return this.$message.warning("该分组内有客户，请先移除再删除");
      } else {
        this.setConfirm(
          `确认删除操作？`,
          {  id: row.id },
          "/admin/group/delete"
        );
      }
    },
     handlePrice(row) {
      // price_type定价方式：1-设置参考价；2-使用采购价
      let str = row.price_type == 1 ? '使用采购价将会自动更新商品规格售价，以最新采购价作为本客户的参考价，您确认开启吗?' : '确认关闭采购价操作？'
      this.$confirm(str,'', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/group/setPriceType', {id: row.id, price_type: row.price_type == 1 ? 2 : 1}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功')
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.user-list {
  max-height: 800px;
}
.user {
  height: 30px;
  line-height: 30px;
}
</style>
