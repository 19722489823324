<template>
  <el-dialog
    append-to-body
    width="660px"
    title="折扣体系"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    >
    <span>参考价：</span>
    <el-switch
      v-model="show_discount"
      :active-value="1"
      :inactive-value="2"
      :active-text="`已${show_discount == 1 ? '开启' : '关闭'}`"
      @change="handleShow">
    </el-switch>
    <span style="margin-left: 20px">使用采购价：</span>
    <el-switch
      v-model="price_type"
      :active-value="2"
      :inactive-value="1"
      :active-text="`已${price_type == 2 ? '开启' : '关闭'}`"
      @change="handlePrice">
    </el-switch>
    <el-button type="primary" @click="setBatch" size="mini" style="margin-bottom:5px;float:right;">批量设置</el-button>
    <el-table
      :data="table.data"
      style="width: 100%"
      :max-height="500"
      :header-cell-style="{background:'#f5f5f5'}"
      border>
      <el-table-column
        type="index"
        label="序号"
        width="60">
      </el-table-column>
      <el-table-column
        prop="cate_name"
        label="商品类别">
      </el-table-column>
      <el-table-column
        prop="object"
        label="参价对象">
        <template slot-scope="{row}">
          <el-input
            placeholder="请输入名称"
            v-model="row.object"
            size="mini">
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="rate"
        label="折扣率">
        <template slot-scope="{row}">
          <el-input
            placeholder="请输入"
            v-model="row.rate"
            size="mini"
            @input="row.rate=row.rate.match(/\d+(\.\d{0,3})?/) ? row.rate.match(/\d+(\.\d{0,3})?/)[0] : ''">
            <i slot="suffix">%</i>
          </el-input>
        </template>
      </el-table-column>
    </el-table>
    

    <el-dialog
      width="500px"
      title=""
      center
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="商品分类" prop="cate_id">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in table.data" :label="item.cate_id" :key="item.cate_id">{{item.cate_name}}</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="参价对象" prop="object">
          <el-input
            placeholder="请输入"
            v-model="form.data.object"
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="折扣率" prop="rate">
          <el-input
            placeholder="请输入"
            v-model="form.data.rate"
            size="mini"
            @input="form.data.rate=form.data.rate.match(/\d+(\.\d{0,3})?/) ? form.data.rate.match(/\d+(\.\d{0,3})?/)[0] : ''">
            <i slot="suffix">%</i>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <el-button type="primary" @click="batchFun">确认</el-button>
    </div>
    </el-dialog>

    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='handleClose'>取消</el-button>
        <el-button type="primary" :loading="loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { getStorage } from '@/storage'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'UserGroupRebate',
    mixins: [mixinTable],
    data() {
      return {
        token: getStorage('token'),
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        table: {
          params:{
            page:1,
            count:10,
            status:1, // 分类1：启用，2禁用
          },
          data: [],
          total:0
        },
        form: {
          data: {
            cate_id: [],
            object: '',
            rate: '',
          },
          rules: {
            object: [{ required: true, message:'请输入参价对象', trigger: ['change', 'blur']}],
            rate: [{ required: true, message:'请输入折扣率', trigger: ['change', 'blur']}],
          }
        },
        currRow: {},
        innerVisible: false,
        isIndeterminate: false,
        checkAll: false,
        checkedCities: [],
        show_discount: true,
        price_type: false,
      }
    },
    methods: {
      // 获取启用的分类
      getDetail(row) {
        this.currRow = row;
        console.log(this.currRow, "***************333")
        this.show_discount = row.show_discount;
        this.price_type = row.price_type; // 定价方式：1-设置参考价；2-使用采购价
        this.table.data = row.discount;
      },
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      setBatch() {
        this.innerVisible = true;
      },
      batchFun() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.data.cate_id = this.checkedCities;
            if(this.form.data.cate_id.length > 0) {
              this.innerVisible = false;
              console.log(this.form.data)
              // 根据分类id相同合参价对象和折扣率
              let arr = this.table.data;
              this.table.data = arr.map(item1 => {
                const item2 = this.form.data.cate_id.find(item2 => item2 === item1.cate_id)
                return item2 ? {...item1,object:this.form.data.object,rate:this.form.data.rate } : item1
              });
              this.$set(this.table.data)
              // 清除上一次数据
              this.$refs.elFormDom.resetFields()
              this.checkedCities = []
              this.form.data = { 
                cate_id: [],
                object: '',
                rate: '',
              };
            } else {
              this.$message.warning("请选择分类")
            }
          }
        })
      },
      // 提交
      confirm() {
        let arr = this.table.data.filter(item => {
          return !!item.object
          // return !!item.object && !!item.rate
        })
        let _discount = arr.map(v => {
          return {
            id: v.id,
            cate_id: v.cate_id,
            object: v.object,
            rate: v.rate,
          }
        })
        let _params = {
          id: this.currRow.id, // 分组id
          discount: _discount
        }
        this.loading = true;
        this.$http.post('/admin/group/discount', _params).then(res => {
          if(res.code === 1) {
            this.toggle(false);
            this.$emit('refresh')
            this.$message.success("操作成功！")
          }
        }).finally(() => {
          this.loading = false;
        })
      },
      // 是否全选
      handleCheckAllChange(val) {
        console.log(val);
        if(val === true) {
          this.table.data.forEach(v => {
            this.checkedCities.push(v.cate_id)
          })
        } else {
          this.checkedCities = []
        }
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.table.data.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.table.data.length;
      },
      handleClose() {
        this.$confirm('若取消不会保存您更新的参价对象及折扣率信息，确认取消吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('refresh')
          this.toggle(false)
        }).catch(() => {})
      },
      // 参考价开关
      handleShow() {
        this.$http.post('/admin/group/showHide', {id: this.currRow.id}).then(res => {
          if(res.code == 1) {
            this.$message.success(this.show_discount == 1 ? '操作成功，参考价将显示在小程序客户端' : '操作成功，参考价不再显示在小程序客户端')
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 使用采购价
      handlePrice() {
        let flag = this.price_type;
        console.log(flag, "flag")
        this.price_type = flag == 2 ? 1 : 2;
        if(this.price_type == 1) {
          this.$confirm('使用采购价将会自动更新商品规格售价，以最新采购价作为本客户的参考价，您确认开启吗?','', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.setPriceType();
          }).catch(() => {})
        } else {
          this.setPriceType()
        }
      },
      setPriceType() {
        this.price_type = this.price_type == 2 ? 1 : 2; //定价方式：1-设置参考价；2-使用采购价
        this.$http.post('/admin/group/setPriceType', {id: this.currRow.id, price_type: this.price_type}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功')
            console.log(this.price_type,"this.price_type")
            if(this.price_type == 2) {
              this.table.data = this.currRow.discount.map(v => {
                return {
                  ...v,
                  object: '采购价'
                }
              })
            } else {
              this.table.data = this.currRow.discount;
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
</script>
